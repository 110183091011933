.marquueback{
    background-color: rgb(248, 214, 234);
}

.colortext{
    color: rgb(2, 94, 125);
}

.callbutton{
    background-color: #560000; /* Green */
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}