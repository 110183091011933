.marblebetai{
    background-color: rgb(245, 250, 253);
}

.callfooter{
    background-color: #082f71; /* Green */
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
